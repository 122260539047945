
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Table from "@/components/Table.vue";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "index",
  components: {
    Field,
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("CBS", ["Customers"]);

      this.store.dispatch(Actions.GET_CUSTOMERS).then(() => {
        const customers = this.store.getters.getCustomersData;

        this.customers.push({ id: 0, name: "Please Select Customer" });

        for (let index of Object.keys(customers)) {
          if (
            customers[index].email !== null &&
            customers[index].email !== "" &&
            customers[index].name !== null &&
            customers[index].name !== ""
          ) {
            this.customers.push(customers[index]);
          }
        }
      });

      this.store.dispatch(Actions.GET_USERS).then(() => {
        const users = this.store.getters.getUsersData.data;
        this.users.push({ id: 0, name: "Please Select FA" });

        for (let index of Object.keys(users)) {
          if (users[index].email !== null && users[index].email !== "") {
            if (users[index].email.includes("@akpk.org.my")) {
              this.users.push(users[index]);
            }
          }
        }
      });
    },
    assign() {
      if (this.assignment.user_id !== 0 && this.assignment.fa_id !== 0) {
        this.store
          .dispatch(Actions.STORE_ASSIGNMENT, this.assignment)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Assignment has been set!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.assignment.user_id = 0;
              this.assignment.fa_id = 0;
            });
          });
      } else {
        Swal.fire({
          title: "Sorry!",
          text: "Please Select Customer and FA!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    },
  },
  data() {
    console.log();
    const store = useStore();
    const route = useRoute();
    const { n, t } = useI18n();
    const assignment = {
      user_id: 0,
      fa_id: 0,
      method_id: 1,
      service_id: 12,
      appointment_date: moment().format("YYYY-MM-DD"),
      start_time: "09:00:00",
      end_time: "09:30:00",
    };

    const customers = [];
    const users = [];

    return {
      store,
      route,
      customers,
      assignment,
      users,
    };
  },
  setup(props) {
    const { t } = useI18n();

    return { t };
  },
});
